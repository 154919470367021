import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import GlobalStyle from "./Global.Styled";
import { ThemeProvider } from "styled-components";
import theme from "./theme";
import Login from "./components/Login/Login";
import Dashboard from "./components/Dashboard/Dashboard";
import "./global.scss";

function App() {
  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <GlobalStyle />
        <Router>
          <Routes>
            <Route index element={<Login />} />
            <Route path={"dashboard/*"} element={<Dashboard />} />
            <Route path={"*"} element={<Navigate replace to="/" />} />
          </Routes>
        </Router>
        <ToastContainer />
      </ThemeProvider>
    </div>
  );
}

export default App;
