import { StyledLogin, HeroForm, CustomHeadline, Flex } from "./Login.Styled";
import { Form, Container, Button, Input } from "@think-internet/ui-components";
import Layout from "../Layout/Layout";
import { useSelector } from "react-redux";
import { useState } from "react";
import { login, resolveToken } from "../../interface/auth";
import toast, { TYPE } from "../../toast";
import Cookies from "universal-cookie";
import { useNavigate } from "react-router-dom";
import props from "../../redux/props";

const Login = () => {
  const translation = useSelector((s) => s[props.TRANSLATION]);
  const [identifier, setIdentifier] = useState("");
  const [password, setPassword] = useState("");
  const cookies = new Cookies();
  const navigate = useNavigate();

  const submit = async () => {
    const token = await login(identifier, password);
    if (token) {
      cookies.set("token", token, { path: "/" });
      const account = await resolveToken(token);
      if (account && account.level) {
        navigate(`/dashboard`);
      } else {
        toast(TYPE.ERROR, translation.login.error);
      }
    } else {
      toast(TYPE.ERROR, translation.login.error);
    }
  };

  return (
    <Layout authRequired={false}>
      <StyledLogin>
        <Container>
          <CustomHeadline text={translation.login.headline} />
          <HeroForm>
            <Form onSubmit={submit}>
              <Flex gap="15px">
                <Input
                  placeholder={translation.generic.mail}
                  onChange={setIdentifier}
                  value={identifier}
                  required={true}
                />
                <Input
                  placeholder={translation.generic.password}
                  onChange={setPassword}
                  value={password}
                  required={true}
                  type="password"
                />
                <Button type="submit" text={translation.login.cta} />
              </Flex>
            </Form>
          </HeroForm>
        </Container>
      </StyledLogin>
    </Layout>
  );
};

export default Login;
