import { Container } from "@think-internet/ui-components";
import { StyledOverview, FeatureList, Feature } from "./Overview.Styled";
import ImageConverter from "../Features/ImageConverter/ImageConverter";
import ZIPGenerator from "../Features/ZIPGenerator/ZIPGenerator";
import AddPasswordToPDF from "../Features/AddPasswordToPDF/AddPasswordToPDF";

const Overview = () => {
  return (
    <StyledOverview>
      <Container>
        <FeatureList>
          <Feature>
            <ImageConverter />
          </Feature>
          <Feature>
            <ZIPGenerator />
          </Feature>
          <Feature>
            <AddPasswordToPDF />
          </Feature>
        </FeatureList>
      </Container>
    </StyledOverview>
  );
};

export default Overview;
