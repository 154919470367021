import { Route, Routes } from "react-router-dom";
import Overview from "./Overview/Overview";

const Default = () => {
  return (
    <Routes>
      <Route path={"*"} element={<Overview />} />
    </Routes>
  );
};

export default Default;
