import styled from "styled-components";

export const StyledLayout = styled.div``;

export const CoreContent = styled.div`
  background-color: ${({ theme }) => theme.color.background.primary};
  min-height: calc(
    100vh - ${({ hideHeader }) => (hideHeader ? "0px" : "80px")}
  );
  margin: ${({ sideMenuItems }) =>
    sideMenuItems ? "0px 0px 50px 200px" : "0px"};
  margin-top: ${({ hideHeader }) => (hideHeader ? "0px" : "80px")};
`;
