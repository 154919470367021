import styled from "styled-components";

export const StyledOverview = styled.div``;

export const FeatureList = styled.div`
  display: flex;
  gap: 15px;
  flex-wrap: wrap;
  padding: 15px 0px;
`;

export const Feature = styled.div`
  width: 100%;
  box-shadow: ${({ theme }) => theme.shadow};
  border-radius: ${({ theme }) => theme.border.radius};
  padding: 10px;
`;
