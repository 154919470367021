import styled from "styled-components";

export const StyledImageConverter = styled.div``;

export const Headline = styled.div`
  font-family: "bold";
  margin-bottom: 15px;
`;

export const UploadLabel = styled.div`
  padding: 40px;
  background-color: ${({ theme }) => theme.color.background.thirdary};
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.color.font.secondary};
`;
