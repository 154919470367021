//
// ONLY EDIT THIS FILE IN /frontend/src/
//

const translation = {
  logoText: "TOOLBOX",

  dashboard: {
    nav: {
      dashboard: "Dashboard",
      users: "Nutzer",
      locations: "Standorte",
    },
    logoutCTA: "Ausloggen",
    loginCTA: "Einloggen",
  },

  imageConverter: {
    headline: "Bilder zu Webp",
    dragAndDropLabel: "Drag & Drop",
    dragAndDropHint: "max. 500 MB | jpg, jpeg, png",
    maxFileSizeExceededToast: "Maximale Dateigröße von 500 MB überschritten",
  },

  zipGenerator: {
    headline: "ZIP mit Passwort",
    passwordPlaceholder: "Passwort",
    dragAndDropLabel: "Drag & Drop",
    dragAndDropHint: "max. 5 GB",
    maxFileSizeExceededToast: "Maximale Dateigröße von 5 GB überschritten",
  },

  addPasswordToPDF: {
    headline: "Passwort zu PDF hinzufügen",
    passwordPlaceholder: "Passwort",
    dragAndDropLabel: "Drag & Drop",
    dragAndDropHint: "max. 500 MB | pdf",
    maxFileSizeExceededToast: "Maximale Dateigröße von 500 MB überschritten",
  },

  imprint: {
    headline: "Impressum",
  },

  dataProtection: {
    headline: "Datenschutz",
  },

  generic: {
    salutation: "Anrede",
    firstname: "Vorname",
    lastname: "Nachname",
    street: "Straße",
    postcode: "Postleitzahl",
    city: "Stadt",
    phone: "Telefon",
    fax: "Fax",
    mail: "Email",
    password: "Passwort",
  },

  login: {
    headline: "Login",
    cta: "Einloggen",
    error: "E-Mail Adresse oder Passwort falsch.",
  },

  UI: {
    dropdown: {
      emptySelection: "Bitte wählen",
    },
  },
};

module.exports = {
  translation,
  langKey: "DE",
};
