import {
  StyledAddPasswordToPDF,
  Headline,
  UploadLabel,
} from "./AddPasswordToPDF.Styled";
import { useSelector } from "react-redux";
import props from "../../../../../redux/props";
import routes from "../../../../../redux/routes";
import useFunctional from "../../../../../interface/useFunctional";
import { DragAndDrop, Input } from "@think-internet/ui-components";
import { useState } from "react";
import { saveFile } from "../../../../../utility";

const AddPasswordToPDF = () => {
  const translation = useSelector((s) => s[props.TRANSLATION]);
  const [uploadActive, setUploadActive] = useState(false);
  const [uploadLabel, setUploadLabel] = useState("");
  const call = useFunctional(routes.add_password_to_pdf);
  const [password, setPassword] = useState("");

  const generateUploadLabel = (progress) => {
    setUploadLabel(`${progress} %`);
  };

  const uploadFiles = async (files) => {
    if (files.length === 1) {
      generateUploadLabel(0);
      setUploadActive(true);
      const result = await call(
        { password },
        translation.addPasswordToPDF.error,
        translation.addPasswordToPDF.succes,
        files,
        (p) => generateUploadLabel(p),
        "application/pdf"
      );
      saveFile(files[0].name, result);
      setPassword("");
      setUploadActive(false);
    }
  };

  return (
    <StyledAddPasswordToPDF>
      <Headline>{translation.addPasswordToPDF.headline}</Headline>
      <Input
        value={password}
        onChange={setPassword}
        placeholder={translation.addPasswordToPDF.passwordPlaceholder}
      />
      {!uploadActive && password.length > 0 && (
        <DragAndDrop
          title={translation.addPasswordToPDF.dragAndDropLabel}
          hint={translation.addPasswordToPDF.dragAndDropHint}
          onFileCallback={uploadFiles}
          maxFileSizeInBytes={500000000}
          allowedFileExtensions={["pdf"]}
          maxFileSizeExceededToast={
            translation.addPasswordToPDF.maxFileSizeExceededToast
          }
          multiple={false}
        />
      )}
      {uploadActive && <UploadLabel>{uploadLabel}</UploadLabel>}
    </StyledAddPasswordToPDF>
  );
};

export default AddPasswordToPDF;
