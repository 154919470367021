import {
  StyledImageConverter,
  Headline,
  UploadLabel,
} from "./ImageConverter.Styled";
import { useSelector } from "react-redux";
import props from "../../../../../redux/props";
import routes from "../../../../../redux/routes";
import useFunctional from "../../../../../interface/useFunctional";
import { DragAndDrop } from "@think-internet/ui-components";
import { useState } from "react";
import { saveFile } from "../../../../../utility";

const ImageConverter = () => {
  const translation = useSelector((s) => s[props.TRANSLATION]);
  const [uploadActive, setUploadActive] = useState(false);
  const [uploadLabel, setUploadLabel] = useState("");
  const call = useFunctional(routes.image_converter);

  const generateUploadLabel = (progress) => {
    setUploadLabel(`${progress} %`);
  };

  const uploadFiles = async (files) => {
    if (files.length > 0) {
      generateUploadLabel(0);
      setUploadActive(true);
      const result = await call(
        {},
        translation.imageConverter.error,
        translation.imageConverter.succes,
        files,
        (p) => generateUploadLabel(p),
        "image/webp"
      );
      saveFile("resultat.webp", result);
      setUploadActive(false);
    }
  };

  return (
    <StyledImageConverter>
      <Headline>{translation.imageConverter.headline}</Headline>
      {!uploadActive && (
        <DragAndDrop
          title={translation.imageConverter.dragAndDropLabel}
          hint={translation.imageConverter.dragAndDropHint}
          onFileCallback={uploadFiles}
          maxFileSizeInBytes={500000000}
          allowedFileExtensions={["png", "jpeg", "jpg"]}
          maxFileSizeExceededToast={
            translation.imageConverter.maxFileSizeExceededToast
          }
          multiple={false}
        />
      )}
      {uploadActive && <UploadLabel>{uploadLabel}</UploadLabel>}
    </StyledImageConverter>
  );
};

export default ImageConverter;
