import { StyledHeader, Item, ItemWrapper, LogoText } from "./Header.Styled";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import props from "../../../redux/props";

const Header = () => {
  const translation = useSelector((s) => s[props.TRANSLATION]);
  const token = useSelector((s) => s[props.TOKEN]);
  const navigate = useNavigate();

  const goHome = () => {
    if (token) {
      navigate("/dashboard/home");
    } else {
      navigate("/");
    }
  };

  return (
    <StyledHeader>
      <ItemWrapper>
        <Item>
          {/* <Logo onClick={goHome} height={'60px'} src={logo} alt="" /> */}
          <LogoText onClick={goHome}>{translation.logoText}</LogoText>
        </Item>
        <Item></Item>
      </ItemWrapper>
    </StyledHeader>
  );
};

export default Header;
