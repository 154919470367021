import { Route, Routes } from "react-router-dom";
import Layout from "../Layout/Layout";
import Default from "./Default/Default";

const Dashboard = () => {
  return (
    <Layout>
      <Routes>
        <Route path={"*"} element={<Default />} />
      </Routes>
    </Layout>
  );
};

export default Dashboard;
