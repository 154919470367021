import { useSelector } from "react-redux";
import customToast, { TYPE } from "../toast";
import call from "./functional";

const useFunctional = (route) => {
  const token = useSelector((s) => s.TOKEN);

  const validate = (status, response, error, success) => {
    if (!error && !success) return response;

    const isValid = () => {
      return !!response && status;
    };

    if (success) {
      if (typeof success === "string") {
        if (isValid()) {
          customToast(TYPE.SUCCESS, success);
          return response;
        }
      } else if (typeof success === "function") {
        const message = success(response);
        if (message) {
          customToast(TYPE.SUCCESS, message);
          return response;
        }
      }
    }

    if (error) {
      if (typeof error === "string") {
        if (!isValid()) {
          customToast(TYPE.ERROR, error);
          return false;
        }
      } else if (typeof error === "function") {
        const message = error(response);
        if (message) {
          customToast(TYPE.ERROR, message);
          return false;
        }
      }
    }
    return response;
  };

  const init = async (
    payload,
    error,
    success,
    files,
    progressCallback,
    fileDownloadMimeType
  ) => {
    const result = await call(
      token,
      route,
      payload,
      files,
      progressCallback,
      fileDownloadMimeType
    );
    if (result instanceof Blob) {
      return result;
    } else {
      const { status, response } = result;
      const validatedResponse = validate(status, response, error, success);
      return validatedResponse;
    }
  };

  return init;
};

export default useFunctional;
